import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { NbTokenService } from '@nebular/auth';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

    user = {};

    constructor(private authService: NbAuthService, private router: Router, private tokens: NbTokenService) {
    }

    canActivate() {
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
                        if (token.isValid()) {
                            this.user = token;
                            localStorage.setItem('selectedUserRole', this.user['payload'].roles[0]);
                        }
                    });
                    if (!authenticated) {
                        this.router.navigate(['auth/signin']);
                    }
                }),
            );
    }
}
