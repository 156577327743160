import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { GlobalProviderService } from '../providers/global-provider.service';
// import Bugsnag from '@bugsnag/js';

@Injectable({
  providedIn: 'root',
})
export class F2fapiService {
  public baseUrl = environment.BASE_URL;
  constructor(
    private globalProvider: GlobalProviderService,
  ) { }

  /*
  ** Get profile
  */
  public getUserProfile() {
    const url = this.baseUrl + 'user/profile';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public updateUserProfile(profileObj) {
    const url = this.baseUrl + 'user/update_profile';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, profileObj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  /*
  ** Register User
  */
  public registerUser(userObj) {
    const url = this.baseUrl + 'register';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, userObj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  /*
  ** Login user
  */
  public loginUser(userObj) {
    const url = this.baseUrl + 'login';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, userObj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  /*
  ** Restaurants APIs
  */
  public getRestaurantsList(perPageData: number, pageNumber: number) {
    const url = this.baseUrl + 'restaurants?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getRestaurantDetails(restaurantId) {
    const url = this.baseUrl + 'restaurants/details/' + restaurantId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public createRestaurant(obj) {
    const url = this.baseUrl + 'restaurants/';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public updateRestaurant(obj, restuarantId) {
    const url = this.baseUrl + 'restaurants/update/' + restuarantId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public removeRestaurantLogo(restuarantId) {
    const url = this.baseUrl + 'restaurants/remove_logo/' + restuarantId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, null)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public updateRestaurantLogo(obj, restuarantId) {
    const url = this.baseUrl + 'restaurants/update_logo/' + restuarantId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public updateRestaurantCoverImg(obj, restuarantId) {
    const url = this.baseUrl + 'restaurants/update_cover_image/' + restuarantId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public removeRestaurantCoverImg(restuarantId) {
    const url = this.baseUrl + 'restaurants/remove_cover_image/' + restuarantId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, null)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public deleteRestaurantDetail(restuarantId) {
    const url = this.baseUrl + 'restaurants/delete/' + restuarantId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpDelete(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getSubscriptionProducts() {
    const url = this.baseUrl + 'products/';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getSetupFeesDetails() {
    const url = this.baseUrl + 'setup_fees/';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public createSetupFeesPaymentIntent(restaurantId) {
    const url = this.baseUrl + 'restaurants/' + restaurantId + '/payment_intent';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getRestaurantSetupDetails(restaurantId, obj) {
    const url = this.baseUrl + 'restaurants/' + restaurantId + '/setup';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }


  /*
  ** Order API
  */

  public getAllOrders(perPageData, pageNumber, obj: object) {
    const url = this.baseUrl + 'restaurants/orders?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }


  public getOrdersByRestaurant(restaurantId) {
    const url = this.baseUrl + 'restaurants/orders/by_restaurant/' + restaurantId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }


  public getOrderDetails(orderId) {
    const url = this.baseUrl + 'restaurants/orders/details/' + orderId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getAllOrderStatuses() {
    const url = this.baseUrl + 'restaurants/orders/order_statuses';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public orderStatusUpdate(orderId, obj: Object) {
    const url = this.baseUrl + 'restaurants/orders/update/' + orderId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }


  /**
   * User Change Password
   */
  public changePassword(userObj) {
    const url = this.baseUrl + 'user/change_password';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, userObj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  /**
   * Forgot Password
   */
  public forgotPassword(userObj) {
    const url = this.baseUrl + 'forgot_password';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, userObj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  /**
   * Reset Password Using Reset Link From Mail
   */
  public resetPassword(userObj, token) {
    const url = this.baseUrl + 'reset_password/' + token;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, userObj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  /*
  ** Get Plans
  */
  public getAllPlans() {
    const url = this.baseUrl + 'plans';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public selectPlan(restaurantId, planObj) {
    const url = this.baseUrl + 'restaurants/' + restaurantId + '/select_plan';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, planObj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }


  /**
   * Get restaurant wise all categories
   */
  public getRestaurantWiseAllCategories(restaurantId, perPageData?, pageNumber?) {
    let url = this.baseUrl + 'restaurants/' + restaurantId + '/menu_item_categories';
    if (perPageData && pageNumber) {
      url = url + '?per_page=' + perPageData + '&page=' + pageNumber;
    }
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  /**
  * Get restaurant wise all categories
  */
  public getRestauranCategory(restaurantId, categoryId) {
    const url = this.baseUrl + 'restaurants/' + restaurantId + '/menu_item_categories/details/' + categoryId;

    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  /**
   * Add Restaurant Category
   * @param restaurantId
   * @param categoryObj
   */
  public addRestaurantCategory(restaurantId, categoryObj) {
    const url = this.baseUrl + 'restaurants/' + restaurantId + '/menu_item_categories';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, categoryObj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  /**
   * Edit Restaurant Category
   * @param restaurantId
   * @param categoryObj
   */
  public updateRestaurantCategory(restaurantId, categoryObj) {
    const url = this.baseUrl + 'restaurants/' + restaurantId + '/menu_item_categories/update/' + categoryObj._id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, categoryObj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  /**
   * Delete Restaurant Category
   * @param restaurantId
   * @param categoryId
   */
  public deleteRestaurantCategory(restaurantId, categoryId) {
    const url = this.baseUrl + 'restaurants/' + restaurantId + '/menu_item_categories/delete/' + categoryId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpDelete(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public checkRestaurantEmail(obj: object) {
    const url = this.baseUrl + 'restaurants/check_email';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  //#region Customer API

  public getAllCustomers(obj: object, perPageData: number, pageNumber: number) {
    const url = this.baseUrl + 'restaurants/customers?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getCustomerDetails(id: string) {
    const url = this.baseUrl + 'restaurants/customers/details/' + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getCustomerOrderList(id: string, obj: object, perPageData: number, pageNumber: number) {
    const url = this.baseUrl + 'restaurants/customers/details/' + id + '/orders?per_page='
      + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region Manager API

  public getAllManagers(obj: object, perPageData: number, pageNumber: number) {
    const url = this.baseUrl + 'restaurants/managers/all?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getManagerDetails(id: string) {
    const url = this.baseUrl + 'restaurants/managers/details/' + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public createManager(obj: object) {
    const url = this.baseUrl + 'restaurants/managers';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public updateManager(obj: object, id: string) {
    const url = this.baseUrl + 'restaurants/managers/update/' + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public deleteManager(id: string) {
    const url = this.baseUrl + 'restaurants/managers/delete/' + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpDelete(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region Reservation APIs

  public filterReservation(obj: object, perPageData: number, pageNumber: number) {
    const url = this.baseUrl + 'restaurants/reservations?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public updateReservation(obj: object, id: string) {
    const url = this.baseUrl + 'restaurants/reservations/update/' + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public reservationDetails(id: string) {
    const url = this.baseUrl + 'restaurants/reservations/details/' + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region Discount APIs

  public getAllDiscountList(id: string, obj: object, perPageData: number, pageNumber: number) {
    const url = this.baseUrl + 'restaurants/' + id + '/discounts/all?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getDiscountDetails(id: string, discountId: string) {
    const url = this.baseUrl + 'restaurants/' + id + '/discounts/details/' + discountId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public createDiscount(id: string, obj: object) {
    const url = this.baseUrl + 'restaurants/' + id + '/discounts/';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public updateDiscount(id: string, discountId: string, obj: object) {
    const url = this.baseUrl + 'restaurants/' + id + '/discounts/update/' + discountId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public deleteDiscount(id: string, discountId: string) {
    const url = this.baseUrl + 'restaurants/' + id + '/discounts/delete/' + discountId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpDelete(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region Payments API

  public getAllPayoutsList(id: string, perPageData: number, pageNumber: number) {
    const url = this.baseUrl + 'restaurants/transactions/' + id + '?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public setupPaymentAccount(id: string) {
    const url = this.baseUrl + 'restaurants/setup_payments/' + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getStoreAccountDetails(id: string) {
    const url = this.baseUrl + 'restaurants/account_details/' + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getStoreAccountBalance(id: string) {
    const url = this.baseUrl + 'restaurants/account_balance/' + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region Review API

  public getAllReviewsList(id: string, perPageData: number, pageNumber: number, obj: object) {
    const url = this.baseUrl + 'restaurants/' + id + '/reviews?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region Menu Editor API

  /**
  * Get all restaurant items
  * @param restaurantId
  */
  getAllRestaurantMenuItems(restaurantId: string, filterObj: Object, perPageData: number, pageNumber: number) {
    const url = this.baseUrl + 'restaurants/' + restaurantId +
      '/menu_items/all?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, filterObj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  /**
  * Get restaurant Menu Item
  */

  public getRestaurantMenuItem(restaurantId, itemId) {
    const url = this.baseUrl + 'restaurants/' + restaurantId + '/menu_items/details/' + itemId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }


  /**
  * Get restaurant Menu Item by category
  */

  public getRestaurantMenuItemByCategory(restaurantId, categoryId) {
    const url = this.baseUrl + 'restaurants/' + restaurantId + '/menu_items/by_category/' + categoryId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  /**
   * Add Menu Item
   */

  public addRestaurantMenuItem(restaurantId, item) {
    const url = this.baseUrl + 'restaurants/' + restaurantId + '/menu_items';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, item)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  /**
   * Update Menu Item
   */

  public updateRestaurantMenuItem(restaurantId, item) {
    const url = this.baseUrl + 'restaurants/' + restaurantId + '/menu_items/update/' + item._id;
    delete item['_id'];
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, item)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }


  public deleteRestaurantMenuItem(restaurantId, itemId) {
    const url = this.baseUrl + 'restaurants/' + restaurantId + '/menu_items/delete/' + itemId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpDelete(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getItemImageData(restaurantId, itemId, obj) {
    const url = this.baseUrl + 'restaurants/' + restaurantId + '/menu_items/update_image/' + itemId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public removeItemImage(restaurantId, itemId) {
    const url = this.baseUrl + 'restaurants/' + restaurantId + '/menu_items/remove_image/' + itemId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, null)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region Analytics API

  public getStatisticsAnalytics() {
    const url = this.baseUrl + 'analytics/statistics';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getAnalyticsCount() {
    const url = this.baseUrl + 'analytics/counts';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getDailyStatistics(obj: object) {
    const url = this.baseUrl + 'analytics/daily_statistics';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region Maps API

  public getStates() {
    const url = this.baseUrl + 'maps/states';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getCities(obj: object) {
    const url = this.baseUrl + 'maps/cities';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region Cuisines APIs

  /*
  ** Get Restaurant Cuisines
  */
  public getRestaurantCuisines() {
    const url = this.baseUrl + 'restaurants/cuisines';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  /*
  ** Get Restaurant Fulfillment Types
  */
  public getRestaurantFulfillmentTypes() {
    const url = this.baseUrl + 'restaurants/fulfillment_types';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion
}
