<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">Truphle</a>
  </div>
</div>

<div class="header-container">
  <div *ngIf="user && isStoreDropDown">
    <nb-select class="store-selection" [selected]="restaurantId" (selectedChange)="restaurantChange($event)"
      [(ngModel)]="restaurantId">
      <nb-option *ngFor="let restuarant of restaurants" [value]="restuarant._id">{{restuarant.name}}</nb-option>
    </nb-select>
  </div>
  <nb-actions size="small">
    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="getUserName()"
        [picture]="user?.picture" nbContextMenuTag="my-context-menu">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>